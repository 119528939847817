body{
  margin-top: 105px;
  position:relative;
  padding: 0 !important;
  overflow: hidden;
}

#wrapper{
  min-width: 320px !important;
  /* max-width: 100vw; */
  margin-left: auto;
  margin-right: auto;
  position:relative;
  background-color: #fff;
  min-height: 80vh;
  transition: 0.5s all ease-in-out;
  max-width: 1545px;
  min-height: 100vh;
  width: 100vw;
}

@media (max-width: 1549px) {
  body{
      overflow-x: hidden !important;
      overflow-y: scroll !important;
  }
}

@media (max-width: 890px) {
  #wrapper{
    margin-left: auto;
    max-width: 640px;
    min-height: unset;
  }
}

@media (max-height: 780px) and (min-height: 600px) {
  #wrapper{
    min-height: 75vh;
    transition: 0.5s all ease-in-out;
  }
}

@media (max-height: 599px) and (min-height: 501px){
  #wrapper{
    min-height: 70vh;
    transition: 0.5s all ease-in-out;
  }
}

@media (max-height: 500px) and (min-height: 431px){
  #wrapper{
    min-height: 65vh;
    transition: 0.5s all ease-in-out;
  }
}

@media (max-height: 430px) {
  #wrapper{
    min-height: 55vh;
    transition: 0.5s all ease-in-out;
  }
}

@media (max-width: 639px){
  body{
    background: #fff;
  }
}

.App {
  text-align: center;
  margin-bottom: 50px;
  padding-top: 50px;
  position:relative;
}

.App.loading {
  opacity: 0.3;
}

.App #logo {
  height:42px;
  transition: 0.5s all ease-in-out;
}

.App #logo.scrolled {
  height:0px;
}

.App #banner {
  transition: 0.5s all ease-in-out;
}
.App #banner.scrolled {
  margin-top: -48px;
}


.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#main-placeholder{
  background-color: #fff;
  width:100%;
  height:100px;
  position: absolute;
  top: -100px;
}

.App-link {
  /*color: #61dafb;*/
}

.error {
  color:red;
}

a {
  color: #1f7eb3;
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 900px) {
  body{
    margin-top: 25px;
  }
}

@media (min-width: 640px) {
  /* #editlink{
    right: calc(50vw - 300px);
  } */
  /* #abc{
    right: calc(50vw - 300px);
  } */
  /* #addbutton{
    right: calc(50vw - 300px) !important;
  } */
}

#personview-header{
  transition: 0.5s all ease-in-out;
}

#personview-header.scrolled{
  position: fixed;
  z-index: 1001;
  width: 100%;
  top: -65px;
  padding-bottom: 0;
  height: 165px;
  max-width: 640px;
  width: 100vw;
}

.with-placeholder label{
  font-size: 14px !important;
}

input:placeholder-shown{
  font-size: 14px !important;
}

select:placeholder-shown{
  font-size: 14px !important;
}

@media (max-width: 899px) {
  .App {
    padding-top: 0px;
  }
}

@media (max-width: 599px) {
  #personview-header.scrolled{
    top: -70px;
  }
  #personview-header.scrolled h5{
    white-space: nowrap;
  }
}

/* prevent ios zoom bug */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .App header input {
    font-size: 16px !important;
  }
}
